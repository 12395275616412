import { render, staticRenderFns } from "./GameTestingDailyGame.vue?vue&type=template&id=9175ad24&scoped=true&"
import script from "./GameTestingDailyGame.vue?vue&type=script&lang=js&"
export * from "./GameTestingDailyGame.vue?vue&type=script&lang=js&"
import style0 from "./GameTestingDailyGame.vue?vue&type=style&index=0&id=9175ad24&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9175ad24",
  null
  
)

export default component.exports