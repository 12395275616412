<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div><v-btn :to="{name:'GameTestingDailyGamePlay'}">Explore</v-btn></div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
   
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    event: (state) => state.event.data,
    boss: (state) => state.boss.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    finished: true,
    showScore: false,
    score: 200,
  }),
  created() {
    //
  },
  mounted() {
    setTimeout(() => {
      this.finished = false;
      this.showScore = true;
    }, 3000);
  },
  methods: {
    next() {
      this.$router.push({
        name: "ForagingGameTestingV2",
      });
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>