<script>
import { mapState } from "vuex";
const game = require("@/games/TowerClimbing/game");
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    skin: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    gameCache: (state) => state.gameCache.data,
  }),
  props: [
    //
  ],
  data: () => ({
    specialItemKey :"S1002_H",
    specialItem:null,
    specialItemType:"hat",
    isHasSpecialItem:false,
    startTime: null,
    schoolYear: null,
    classroomMonth: 3,
    showDifficulty: true,
    canStart: false,
    difficulty: 1,
    endTime: null,
    env: process.env,
    containerId: "game-container",
    gameInstance: null,
    master: 0,
    music: 0,
    sfx: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    avatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    InventoryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    InventoryApparelApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    footApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    avatarData: null,
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
    gameData: {},
    questions: [],
    wizard: {},
    end: {},
    game: {},
    blueStar: 20,
    ladder: 0,
    timestampReset:null,
    colors: [
      "F5B7B1",
      "C39BD3",
      "AED6F1",
      "A3E4D7",
      "F9E79F",
      "E6B0AA",
      "A9DFBF",
      "FAD7A0",
      "D2B4DE",
      "DFFF00",
      "CCCCFF",
      "40E0D0",
    ],
  }),
  created() {
    // if (this.$route.query.difficulty) {
    //   this.difficulty = this.$route.query.difficulty;
    // } else {
    //   this.difficulty = 1;
    // }
    this.gameApi.url =
      this.$api.servers.game +
      "/api/v2/" +
      this.$_getLocale() +
      "/game/dailyGame/visit";

    this.gameApi.callbackReset = () => {
      this.gameApi.isLoading = true;
      this.gameApi.isError = false;
    };

    this.gameApi.callbackError = (e) => {
      this.gameApi.isLoading = false;
      this.gameApi.isError = true;
      this.gameApi.error = e;
    };
    this.gameApi.callbackSuccess = (resp) => {
      this.questions = resp.Data.Question.reverse();
      this.wizard = resp.Data.Wizard;
      this.end = resp.Data.End;
      this.game = resp.Game;
      this.gameApi.isLoading = false;
      this.canStart = true;
        this.clickStartGame()
      
    };
    this.footApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/footprint/check";

    this.footApi.callbackReset = () => {
      this.footApi.isLoading = true;
      this.footApi.isError = false;
    };

    this.footApi.callbackError = (e) => {
      this.footApi.isLoading = false;
      this.footApi.isError = true;
      this.footApi.error = e;
    };
    this.footApi.callbackSuccess = (resp) => {
      this.footApi.isLoading = false;
      this.timestampReset = resp.TimestampReset != null
        ? this.$moment(resp.TimestampReset)
        : null;

        if(resp.TimestampReset != null){
          this.ladder = resp.Footprint.quantity;
        }
        this.gameApi.params = {
      schoolYear: this.schoolYear,
      classroomMonth : this.classroomMonth
    };
        this.$api.fetch(this.gameApi);
       
    };
    this.InventoryApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory/listByType";

    this.InventoryApi.callbackReset = () => {
      this.InventoryApi.isLoading = true;
      this.InventoryApi.isError = false;
    };
    this.InventoryApi.callbackError = (e) => {
      this.InventoryApi.isLoading = false;
      this.InventoryApi.isError = true;
      this.InventoryApi.error = e;
    };
    this.InventoryApi.callbackSuccess = (resp) => {
      let blueStar = [];
     // let ladder = [];
      blueStar = this.$_.find(resp.Item, { key: "C000040" });
      // ladder = this.$_.find(resp.Item, { key: "C000050" });
      this.blueStar = blueStar.inventory;
      //this.ladder = ladder.inventory;
      //console.log(this.ladder)
      this.InventoryApi.isLoading = false;
      
    this.$api.fetch(this.footApi);
   
    };

    this.InventoryApparelApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory/listByType";

    this.InventoryApparelApi.callbackReset = () => {
      this.InventoryApparelApi.isLoading = true;
      this.InventoryApparelApi.isError = false;
    };
    this.InventoryApparelApi.callbackError = (e) => {
      this.InventoryApparelApi.isLoading = false;
      this.InventoryApparelApi.isError = true;
      this.InventoryApparelApi.error = e;
    };
    this.InventoryApparelApi.callbackSuccess = (resp) => {
      
      let apparel = [];
     // let ladder = [];
     apparel = this.$_.find(resp.Item, { key: this.specialItemKey });
      // ladder = this.$_.find(resp.Item, { key: "C000050" });
      if(apparel.inventory >0){
        this.isHasSpecialItem = true;
      }
      //this.ladder = ladder.inventory;
      //console.log(this.ladder)
      this.InventoryApparelApi.isLoading = false;
      
    this.$api.fetch(this.InventoryApi);
   
    };

   
    this.api.url = this.$api.servers.question + "/questions";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
     
      this.api.isLoading = false;

     
        this.canStart = true;
      
    };
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear =this.auth.Classroom.schoolYear;
        this.classroomMonth = this.auth.Classroom.month
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
      this.classroomMonth = this.auth.User.month
    }
    this.startTime = new Date();
    this.InventoryApi.params = {
      type: "currency",
    };
    this.InventoryApparelApi.params = {
      type: "apparel",
    };
    this.avatarData = JSON.parse(this.auth.Player.avatar);
    this.selectSkin(this.avatarData);
    this.$api.fetch(this.InventoryApparelApi);
  },
  beforeDestroy() {
    this.endTime = new Date(); // Record end time when element is about to be destroyed
  
    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    clickStartGame() {
      if (this.canStart) {
       // this.$store.dispatch("resetGameCache");
        this.showDifficulty = false;
        this.startGame();
      }
    },
    startGame() {
      this.gameInstance = game.launch(this.containerId);
      this.gameInstance.scene.start("LoadingScene", {
        avatar: this.avatarItem,
        specialItem: this.specialItem,
        isHasSpecialItem:this.isHasSpecialItem,
        ladder: this.ladder,
        blueStar: this.blueStar,
        game: this.game,
        end: this.end,
        wizard: this.wizard,
        questions: this.questions,
        colors: this.colors,
        env: this.env,
        timestampReset:this.timestampReset,
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
    selectSkin(avatarData) {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = avatarData.body;
      var mouth = avatarData.mouth;
      var hat = avatarData.hat;
      var left = avatarData.left;
      var right = avatarData.right;
      var back = avatarData.back;
      var specialItemKey = this.specialItemKey
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }
      if (left) {
        var avatarLeft = this.hatData.filter(function (entry) {
          return entry.key == left;
        });
        this.avatarLeft = JSON.parse(avatarLeft[0].images).apparel.url;
      }
      if (right) {
        var avatarRight = this.hatData.filter(function (entry) {
          return entry.key == right;
        });
        this.avatarRight = JSON.parse(avatarRight[0].images).apparel.url;
      }
      if (back) {
        var avatarBack = this.hatData.filter(function (entry) {
          return entry.key == back;
        });
        this.avatarBack = JSON.parse(avatarBack[0].images).apparel.url;
      }
      var specialItem = this.hatData.filter(function (entry) {
          return entry.key == specialItemKey;
        });
        this.specialItem = JSON.parse(specialItem[0].images).apparel.url;
      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = avatarData.pColor;
        this.avatarItem.back = this.avatarBack;
        this.avatarItem.body = this.avatarBody;
        this.avatarItem.eye = this.avatarEye;
        this.avatarItem.hat = this.avatarHat;
        this.avatarItem.left = this.avatarLeft;
        this.avatarItem.mouth = this.avatarMouth;
        this.avatarItem.right = this.avatarRight;
        this.avatarItem.shadow = this.avatarShadow;
        this.avatarItem.outline = this.avatarOutline;
        this.avatarItem.cheek = this.avatarCheek;
        this.avatarItem.hair = this.avatarHair;
        this.avatarItem.color = this.avatarColor;

    },
  },
};
</script>

<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <!-- <div
            class="d-flex flex-column justify-center align-center dif"
            v-if="showDifficulty"
          >
            <v-btn v-if="!this.gameCache" class="ma-2" @click="clickStartGame()" width="260">Start game</v-btn>
          </div> -->

            <div
              :id="containerId"
              style="max-width: 480px; padding: 0; max-height: 100vh !important"
            />
            <div
              v-if="!gameInstance && !showDifficulty "
              style="position: absolute"
              class="d-flex align-center justify-center flex-column"
            >
              <v-progress-linear
                color="brown darken-1"
                indeterminate
                height="10"
                style="max-width: 200px"
                class="my-5"
              ></v-progress-linear>
              <span class="brown--text py-2">
                {{ $t("string.game_loading_msg") }}</span
              >

              <v-btn
                depressed
                color="brown lighten-3"
                class="brown--text text--darken-3"
                @click="
                  () => {
                    $router.go($router.currentRoute);
                  }
                "
              >
                ↻ {{ $t("action.retry") }}
              </v-btn>
            </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>

<style>
.game-body {
  max-width: 480px;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(49, 214, 49, 0.3);
  /* background-image: url("../../assets/background/BG_02.png"); */
  position: relative;
}

.dif{
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>